<template>
  <v-container>
    <v-row no-gutters>
      <h2>{{ $t("pages.project_git_repository.title") }}</h2>
    </v-row>
    <v-row no-gutters v-if="project == null">
      <v-skeleton-loader type="text, text, text, button" />
    </v-row>
    <v-row no-gutters v-else>
      <form @submit.prevent="setRepoCreds()" class="row">
        <v-col cols="12">
          <v-text-field
            :label="$t('form.create_project.repo_url.label')"
            v-model="repoURL"
            :rules="[validRepoURL]"
            autofocus
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$t('form.create_project.repo_username.label')"
            v-model="repoURLUsername"
          />
        </v-col>
        <v-col cols="12">
        <v-text-field
          :label="$t('form.create_project.repo_password.label')"
          type="password"
          v-model="repoURLPassword"
        />
        </v-col>
        <v-card-actions class="px-0">
          <v-btn color="primary mt-2" type="submit">OK</v-btn>
        </v-card-actions>
      </form>
    </v-row>
  </v-container>
</template>

<script>
import projects from '../../services/projects'
export default {
  name: 'GitRepository',
  created() {
    projects.getProjectById(this.$route.params.projectId).then(res => {
      this.project = res
      this.repoURL = res.info.repoUrl
    })
  },
  components: {
  },
  methods: {
    setRepoCreds() {
      projects.setRepoCreds(
        {
          projectId: this.project.info.projectId,
          repoUrl: this.repoURL,
          repoUsername: this.repoURLUsername,
          repoPwd: this.repoURLPassword,
        }
      ).then(res => {
        this.$notifInfo(this.$t('notif.success_set_repo_creds', [this.project.info.projectId]))
        this.$router.push({name: 'ProjectOverview'})
      }).catch(err => {
        this.$notifError(this.$t('notif.failed_set_repo_creds', [this.project.info.projectId]), err)
      })
    }
  },
  computed: {
    validRepoURL() {
      if (/^http(s)?:\/\/.*$/.test(this.repoURL)) {
        return true
      }
      return 'Must be a valid http URL. For example https://github.com/octocat/Hello-World.git.'
    }
  },
  data: () => ({
    project: null,

    repoURL: "",
    repoURLUsername: "",
    repoURLPassword: "",

    setWaiting: false,
  }),
}
</script>
